import React, { useState, useEffect } from "react";
import HeaderDashboard from "../Components/HeaderDashboard";
import "./MainDashboard.css";

const MainDashboard = () => {
  const userSession = localStorage.getItem("session");

  const monthlyTotal = { session: userSession, proforma: true };
  const monthlyPatients = { session: userSession };
  const monthlyOpen = { session: userSession };

  const [monthlyTotal1, setMonthlyTotal1] = useState(0);
  const [monthlyTotal2, setMonthlyTotal2] = useState(0);
  const [monthlyTotal3, setMonthlyTotal3] = useState(0);

  const [monthlypatients1, setmonthlypatients1] = useState(0);
  const [monthlypatients2, setmonthlypatients2] = useState(0);
  const [monthlypatients3, setmonthlypatients3] = useState(0);

  const [monthlyOpen1, setMonthlyOpen1] = useState(0);
  const [monthlyOpen2, setMonthlyOpen2] = useState(0);
  const [monthlyOpen3, setMonthlyOpen3] = useState(0);

  useEffect(() => {
    getMonthlyTotal();
    getMonthlyPatients();
    getMonthlyOpen();
  }, []);

  const getMonthlyTotal = () => {
    fetch("https://bo.drjubot.com/invoices.asmx/monthlytotal", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(monthlyTotal),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setMonthlyTotal1(data.totalnowm0);
        setMonthlyTotal2(data.totalnowm1);
        setMonthlyTotal3(data.totalnowm2);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };

  const getMonthlyPatients = () => {
    fetch("https://bo.drjubot.com/invoices.asmx/monthlypatients", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(monthlyPatients),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setmonthlypatients1(data.totalnowm0);
        setmonthlypatients2(data.totalnowm1);
        setmonthlypatients3(data.totalnowm2);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };

  const getMonthlyOpen = () => {
    fetch("https://bo.drjubot.com/invoices.asmx/monthlyopen", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(monthlyOpen),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setMonthlyOpen1(data.totalnowm0);
        setMonthlyOpen2(data.totalnowm1);
        setMonthlyOpen3(data.totalnowm2);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };
  return (
    <div className="main-dashboard-container">
      <HeaderDashboard title="מסך ראשי" />
      <div className="two-square-container">
        <div className="square">
          <h3 className="square-title">סך הכנסות</h3>
          <h5 className="square-content">חודש ראשון: {monthlyTotal1}</h5>
          <h5 className="square-content">חודש שני: {monthlyTotal2}</h5>
          <h5 className="square-content">חודש שלישי: {monthlyTotal3}</h5>
        </div>
        <div className="square">
          <h3 className="square-title">מספר מטופלים </h3>
          <h5 className="square-content">חודש ראשון: {monthlypatients1}</h5>
          <h5 className="square-content">חודש שני: {monthlypatients2}</h5>
          <h5 className="square-content">חודש שלישי: {monthlypatients3}</h5>
        </div>
      </div>
      <div className="one-square-container">
        <div className="square">
          <h3 className="square-title">סך דרישות תשלום פתוחות</h3>
          <h5 className="square-content">חודש ראשון: {monthlyOpen1}</h5>
          <h5 className="square-content">חודש שני: {monthlyOpen2}</h5>
          <h5 className="square-content">חודש שלישי: {monthlyOpen3}</h5>
        </div>
      </div>
    </div>
  );
};

export default MainDashboard;
