import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AfterForgotPass.css";
import Footer from "../Components/Footer";

const AfterForgotPass = ({ session, name, tempPass, id }) => {
  const [formData, setFormData] = useState({
    id: id,
    oldpassword: tempPass,
    newpassword: "",
    session: session,
  });
  const [validation, setValidation] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch("https://bo.drjubot.com/users.asmx/userupdatepassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(formData.newpassword);
        console.log(confirmPass);
        if (formData.newpassword !== confirmPass) {
          setValidation("אישור סיסמה חדשה אינו נכון, אנא הקלידו שוב את הסיסמה");
          return;
        } else {
          setValidation("");
          navigate("/dashboard");
        }
        console.log(data);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };

  return (
    <>
      <div className="box-container">
        <h1 className="title">סיסמא חדשה</h1>
        <form onSubmit={(e) => handleSubmit(e)} className="form">
          <h2 className="label">שם משתמש</h2>
          <input
            className="input"
            type="text"
            autofill="off"
            dir="ltr"
            placeholder={name}
          />
          <h2 className="label">סיסמא חדשה</h2>
          <input
            className="input"
            autoComplete="off"
            type="password"
            autofill="off"
            dir="ltr"
            onChange={(e) =>
              setFormData({ ...formData, newpassword: e.target.value })
            }
          />
          <h2 className="label">חזור על הסיסמא החדשה</h2>
          <input
            className="input"
            autoComplete="off"
            type="password"
            autofill="off"
            dir="ltr"
            onChange={(e) => {
              setConfirmPass(e.target.value);
            }}
          />
          {validation && <p style={{ color: "red" }}>{validation}</p>}
          <button className="submit-button" type="submit">
            המשך
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default AfterForgotPass;
