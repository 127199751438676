import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-container">
      <img
        src="../assets/logo.svg"
        alt="DrJubot logo"
        className="logo-footer"
      />
      <div className="desc-footer">
        <Link className="link-footer">Contact us</Link>
        <Link className="link-footer">Terms and condition</Link>
        <Link className="link-footer">Privacy policy</Link>
        <p className="all-right">All right reserved DrJubot ©</p>
      </div>
    </div>
  );
};

export default Footer;
