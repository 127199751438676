import React, { useContext, useState, useEffect } from "react";
import "./Patients.css";
import HeaderDashboard from "../Components/HeaderDashboard";
import { HiDotsVertical } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { DrjubotContext } from "../DrjubotContextProvider";

const Patients = () => {
  const { patientFilter } = useContext(DrjubotContext);
  const navigate = useNavigate();

  const [dotsPopUp, setDotsPopUp] = useState(-1);

  return (
    <div>
      <HeaderDashboard
        title="מטופלים"
        placeholder="חיפוש לפי ת.ז שם פרטי, שם משפחה, מספר טלפון"
      />

      <div className="table">
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>ת.ז</th>
                <th>שם מלא</th>
                <th>טלפון</th>
                <th>אימייל</th>
                <th>כתובת</th>
                <th></th>
              </tr>
            </thead>
            {patientFilter.length > 0 &&
              patientFilter.map((patient, i) => {
                return (
                  <tbody key={i}>
                    <tr>
                      <td>{patient.patientid}</td>
                      <td>
                        {patient.firstname} {patient.lastname}
                      </td>
                      <td>{patient.mobile}</td>
                      <td>{patient.email}</td>
                      <td>{patient.address}</td>
                      <td>
                        <div className="dots-container">
                          {dotsPopUp === i && (
                            <div className="dots-popup-container">
                              <div className="link-list">
                                <button
                                  className="popup-link"
                                  onClick={() =>
                                    navigate(
                                      `/dashboard/profile?patientId=${patient.patientid}&patientFirstName=${patient.firstname}&patientLastName=${patient.lastname}&patientPhone=${patient.mobile}&patientEmail=${patient.email}&patientAddress=${patient.address}&company=${patient.company}`
                                    )
                                  }
                                >
                                  ערוך פרטים
                                </button>
                                <button
                                  className="popup-link"
                                  onClick={() =>
                                    navigate(
                                      `/dashboard/demand-of-payment?patientFirstName=${patient.firstname}&patientLastName=${patient.lastname}`
                                    )
                                  }
                                >
                                  צור דרישת תשלום
                                </button>
                                <Link className="popup-link">
                                  יצירת חשבונית
                                </Link>
                              </div>
                            </div>
                          )}
                          <HiDotsVertical
                            size={24}
                            onClick={() => {
                              if (i === dotsPopUp) {
                                setDotsPopUp(-1);
                              } else setDotsPopUp(i);
                            }}
                            className="patients-dots"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
          </table>
        </div>
      </div>
    </div>
  );
};

export default Patients;
