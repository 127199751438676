import React, { useState, useEffect, useContext } from "react";
import RegisterPart2 from "./RegisterPart2";
import "./Register.css";
import Footer from "../Components/Footer";
import { DrjubotContext } from "../DrjubotContextProvider";

const Register = () => {
  const { setCurrentUser } = useContext(DrjubotContext);

  const [base64IMGProfile, setBase64IMGProfile] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    fullname: "",
    email: "",
    address: "",
    mobile: "",
    password: "",
    userimage: base64IMGProfile,
    license: "",
    organisationId: -1,
  });
  const [confirmPass, setConfirmPass] = useState("");
  const [validation, setValidation] = useState("");
  const [registerPart2, setRegisterPart2] = useState(false);
  const [registerPart1, setRegisterPart1] = useState(true);
  const [userId, setUserId] = useState(0);

  useEffect(() => {
    handleUserExists();
  }, [formData.name]);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load something...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        const base64text = baseURL.slice(22);
        console.log(base64text);
        setBase64IMGProfile(base64text);
        setFormData({ ...formData, userimage: base64text });
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  const handleFileInputChange = (e) => {
    console.log(e.target.files[0]);

    let file = e.target.files[0];

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        console.log("File Is", file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch("https://bo.drjubot.com/users.asmx/userregister", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (
          formData.name === "" ||
          formData.fullname === "" ||
          formData.license === "" ||
          formData.address === "" ||
          formData.mobile === "" ||
          formData.password === ""
        ) {
          setValidation("אחד מהשדות ריקים, אנא מלאו את כל השדות בטופס זה");
        } else if (confirmPass !== formData.password) {
          setValidation("אישור הסיסמה אינו נכון");
        } else if (data.error.code !== 0) {
          setValidation("ולידציה כלשהי");
        } else {
          setValidation("");
          setUserId(data.id);
          setCurrentUser({
            fullname: data.fullname,
            email: data.email,
            image: data.userimage,
            session: data.session,
          });
          console.log({ formData });
          setRegisterPart1(false);
          setRegisterPart2(true);
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };

  const handleUserExists = () => {
    fetch("https://bo.drjubot.com/users.asmx/userexists", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: formData.name,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (formData.name === "") {
          setValidation("");
        } else if (data.name === formData.name) {
          setValidation("משתמש זה כבר קיים במערכת");
          return true;
        } else {
          setValidation("");
          return false;
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };
  // auto complete standards: https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#attr-fe-autocomplete-new-password
  return (
    <div className="page-container">
      <img src="../assets/logo.svg" alt="DrJubot Logo" className="logo" />
      {registerPart1 && (
        <div className="box-container">
          <h1 className="title">הירשם</h1>
          <form
            onSubmit={(e) => handleSubmit(e)}
            autofill="off"
            className="register-form"
          >
            <div>
              <h2 className="label">תמונת פרופיל</h2>
              <input
                type="file"
                name="file"
                id="file"
                className="inputfile"
                onChange={handleFileInputChange}
              />
              <label for="file">
                {base64IMGProfile ? (
                  <img src={`data:image/png;base64, ${base64IMGProfile}`} />
                ) : (
                  <img src="../assets/upload-photo.png" />
                )}
              </label>
            </div>
            <div className="first-col">
              <h2 className="label">שם משתמש</h2>
              <input
                className="input"
                type="text"
                autofill="off"
                dir="ltr"
                autoComplete="username"
                onChange={(e) => {
                  setFormData({ ...formData, name: e.target.value });
                }}
              />

              <h2 className="label">סיסמה</h2>
              <input
                className="input"
                type="password"
                dir="ltr"
                autoComplete="new-password"
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
              />

              <h2 className="label">חזור על הסיסמה</h2>
              <input
                className="input"
                type="password"
                dir="ltr"
                autoComplete="new-password"
                onChange={(e) => {
                  setConfirmPass(e.target.value);
                }}
              />

              <h2 className="label">שם מלא</h2>
              <input
                className="input"
                type="text"
                autoComplete="name"
                onChange={(e) =>
                  setFormData({ ...formData, fullname: e.target.value })
                }
              />
              <button type="submit" className="submit-button">
                המשך
              </button>
              {validation && <p style={{ color: "red" }}>{validation}</p>}
            </div>
            <div>
              <h2 className="label">מספר רישיון</h2>
              <input
                className="input"
                type="text"
                dir="ltr"
                autoComplete="off"
                onChange={(e) =>
                  setFormData({ ...formData, license: e.target.value })
                }
              />

              <h2 className="label">אימייל</h2>
              <input
                className="input"
                type="email"
                dir="ltr"
                autoComplete="email"
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />

              <h2 className="label">כתובת</h2>
              <input
                className="input"
                type="address"
                autoComplete="street-address"
                onChange={(e) =>
                  setFormData({ ...formData, address: e.target.value })
                }
              />

              <h2 className="label">נייד</h2>
              <input
                className="input"
                type="phone"
                dir="ltr"
                autoComplete="tel-local"
                onChange={(e) =>
                  setFormData({ ...formData, mobile: e.target.value })
                }
              />
            </div>
          </form>
        </div>
      )}
      {registerPart2 && <RegisterPart2 userId={userId} />}
      <Footer />
    </div>
  );
};

export default Register;
