import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AfterForgotPass from "./AfterForgotPass";
import "./Login.css";
import Footer from "../Components/Footer";
import { DrjubotContext } from "../DrjubotContextProvider";

const Login = () => {
  const [formData, setFormData] = useState({
    name: "",
    password: "",
    organisationId: -1,
  });
  const [validation, setValidation] = useState("");
  const [session, setSession] = useState("");
  const [showLogin, setShowLogin] = useState(true);
  const [showAfterForgotPass, setShowAfterForgotPass] = useState(false);
  const [tempPass, setTempPass] = useState("");
  const [name, setName] = useState("");
  const [id, setId] = useState(0);
  const navigate = useNavigate();
  const { setCurrentUser } = useContext(DrjubotContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch("https://bo.drjubot.com/users.asmx/userlogin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.error.code === 1) {
          setValidation(
            "שם המשתמש או הסיסמה אינם נכונים. במידה ושכחתם את אחד מהם, נא ללחוץ על כפתור ״שכחתי סיסמה״"
          );
        } else if (data.pswexpired) {
          const userSession = data.session;
          localStorage.setItem("session", userSession);
          setId(data.id);
          setSession(data.session);
          setName(data.name);
          setShowLogin(false);
          setShowAfterForgotPass(true);
          setValidation("");
        } else {
          const userSession = data.session;
          localStorage.setItem("session", userSession);
          setShowLogin(true);
          setShowAfterForgotPass(false);
          setValidation("");
          setCurrentUser({
            fullname: data.fullname,
            email: data.email,
            image: data.userimage,
            session: data.session,
          });
          localStorage.setItem("username", data.name);
          navigate("/dashboard");
        }
        console.log(data);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };

  return (
    <>
      <div className="page-container">
        <img src="../assets/logo.svg" alt="DrJubot Logo" className="logo" />
        {showLogin && (
          <div className="box-container">
            <h1 className="title">התחבר</h1>
            <form className="form" onSubmit={(e) => handleSubmit(e)}>
              <h2 className="label">שם משתמש</h2>
              <input
                className="input"
                type="text"
                dir="ltr"
                autofill="off"
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
              />
              <h2 className="label">סיסמה</h2>
              <input
                className="input"
                autoComplete="off"
                autofill="off"
                dir="ltr"
                type="password"
                onChange={(e) => {
                  setTempPass(e.target.value);
                  setFormData({ ...formData, password: e.target.value });
                }}
              />
              {validation && <p style={{ color: "red" }}>{validation}</p>}
              <Link to="/forgot-password" className="forget-pass">
                שכחתי סיסמא?
              </Link>
              <button type="submit" className="submit-button">
                המשך
              </button>
              <div className="not-register-yet-container">
                <h2 className="not-register-yet-title">לא נרשמת עדיין?</h2>
                <button
                  type="button"
                  onClick={() => {
                    navigate("/register");
                  }}
                  className="register-button"
                >
                  הרשם
                </button>
              </div>
            </form>
          </div>
        )}
        {showAfterForgotPass && (
          <AfterForgotPass
            session={session}
            name={name}
            tempPass={tempPass}
            id={id}
          />
        )}
      </div>
      <Footer />
    </>
  );
};

export default Login;
