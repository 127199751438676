import React, { useState } from "react";
import RegisterPart3 from "./RegisterPart3";
import "./RegisterPart2.css";

const RegisterPart2 = ({ userId }) => {
  const [base64IMG, setBase64IMG] = useState("");
  const [formData, setFormData] = useState({
    id: userId,
    name: "",
    logo: base64IMG,
    address: "",
    contactphone: "",
    contactemail: "",
    registernum: "",
    admin: userId,
    showlogo: true,
    showaddress: true,
    showphone: true,
    showemail: true,
    showbankdetails: true,
    bankdetails: {
      bankname: "",
      bankcode: 0,
      branch: "",
      accountname: "",
      accountnumber: "",
    },
  });
  const [validation, setValidation] = useState("");
  const [showRegisterPart2, setShowRegisterPart2] = useState(true);
  const [showRegisterPart3, setShowRegisterPart3] = useState(false);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load something...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        const base64text2 = baseURL.slice(22);
        console.log(base64text2);
        setBase64IMG(base64text2);
        setFormData({ ...formData, logo: base64text2 });
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  const handleFileInputChange = (e) => {
    console.log(e.target.files[0]);

    let file = e.target.files[0];

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        console.log("File Is", file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = () => {
    if (
      formData.name === "" ||
      formData.registernum === "" ||
      formData.contactphone === "" ||
      formData.contactemail === "" ||
      formData.address === "" ||
      formData.logo === ""
    ) {
      setValidation("אחד מהשדות ריקים, אנא מלאו את כל השדות בטופס זה");
    } else {
      setValidation("");
      setShowRegisterPart2(false);
      setShowRegisterPart3(true);
    }
  };
  return (
    <div>
      {showRegisterPart2 && (
        <div className="box-container-register">
          <h1 className="title">יצירת איגוד</h1>
          <form className="register-form">
            <div className="first-col">
              <h2 className="label">שם האיגוד</h2>
              <input
                className="input"
                type="text"
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
              />

              <h2 className="label">ח.פ / ת.ז</h2>
              <input
                className="input"
                type="text"
                onChange={(e) =>
                  setFormData({ ...formData, registernum: e.target.value })
                }
              />

              <h2 className="label">טלפון</h2>
              <input
                className="input"
                type="text"
                onChange={(e) =>
                  setFormData({ ...formData, contactphone: e.target.value })
                }
              />

              <h2 className="label">אימייל</h2>
              <input
                className="input"
                type="email"
                onChange={(e) =>
                  setFormData({ ...formData, contactemail: e.target.value })
                }
              />

              <h2 className="label">כתובת</h2>
              <input
                className="input"
                type="phone"
                onChange={(e) =>
                  setFormData({ ...formData, address: e.target.value })
                }
              />
              {validation && <p style={{ color: "red" }}>{validation}</p>}
              <button
                className="submit-button"
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
              >
                המשך
              </button>
            </div>
            <div className="sec-col">
              <h2 className="label">לוגו</h2>
              <input
                type="file"
                name="file"
                id="file"
                className="inputfile"
                onChange={handleFileInputChange}
              />
              <label for="file">
                {base64IMG ? (
                  <img src={`data:image/png;base64, ${base64IMG}`} />
                ) : (
                  <img src="../assets/upload-photo.png" />
                )}
              </label>
              <div className="check">
                <input
                  checked={formData.showlogo}
                  type="checkbox"
                  onChange={() =>
                    setFormData({ ...formData, showlogo: !formData.showlogo })
                  }
                />
                <label className="label-check">הצג לוגו על גביי חשבונית</label>
              </div>
              <div className="check">
                <input
                  checked={formData.showemail}
                  type="checkbox"
                  onChange={() =>
                    setFormData({ ...formData, showemail: !formData.showemail })
                  }
                />
                <label className="label-check">
                  הצג אימייל על גביי חשבונית
                </label>
              </div>
              <div className="check">
                <input
                  checked={formData.showaddress}
                  type="checkbox"
                  onChange={() =>
                    setFormData({
                      ...formData,
                      showaddress: !formData.showaddress,
                    })
                  }
                />
                <label className="label-check">הצג כתובת על גביי חשבונית</label>
              </div>
              <div className="check">
                <input
                  checked={formData.showphone}
                  type="checkbox"
                  onChange={() =>
                    setFormData({ ...formData, showphone: !formData.showphone })
                  }
                />
                <label className="label-check">הצג טלפון על גביי חשבונית</label>
              </div>
            </div>
          </form>
        </div>
      )}
      {showRegisterPart3 && (
        <RegisterPart3 formData={formData} setFormData={setFormData} />
      )}
    </div>
  );
};

export default RegisterPart2;
