import React from "react";
import { NavLink } from "react-router-dom";
import "./SideNav.css";
import UserSideNav from "./UserSideNav";
import { TbFileInvoice } from "react-icons/tb";
import { IoNotificationsOutline } from "react-icons/io5";
import { IoDocumentsOutline } from "react-icons/io5";
import { MdOutlineSupervisorAccount } from "react-icons/md";

const SideNav = () => {
  return (
    <div className="sidenav-container">
      <img src="../assets/logo.svg" className="sidenav-logo" />
      <div className="navlink-list">
        <nav>
          <NavLink to="/dashboard/main" className="link">
            <TbFileInvoice className="svg-icon" size={20} />
            ראשי
          </NavLink>
          <NavLink className="link" to="/login">
            <IoDocumentsOutline className="svg-icon" size={20} />
            מסמכים והכנסות
          </NavLink>
          <NavLink className="link" to="/dashboard/patients">
            <MdOutlineSupervisorAccount className="svg-icon" size={20} />
            מטופלים
          </NavLink>
          <NavLink className="link" to="/login">
            <IoDocumentsOutline className="svg-icon" size={20} />
            חיפוש מסמכים
          </NavLink>
          <NavLink className="link" to="/login">
            <TbFileInvoice className="svg-icon" size={20} />
            דוחות תקופתיים
          </NavLink>
          <NavLink className="link" to="/login">
            <TbFileInvoice className="svg-icon" size={20} />
            הגדרות האיגוד
          </NavLink>
          <NavLink className="link" to="/login">
            <IoNotificationsOutline className="svg-icon" size={20} />
            התראות
          </NavLink>
        </nav>
      </div>
      <UserSideNav />
    </div>
  );
};

export default SideNav;
