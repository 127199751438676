import React, { useState, useEffect } from "react";
import "./DemandOfPayment.css";
import { useSearchParams } from "react-router-dom";
import { DrjubotContext } from "../DrjubotContextProvider";
import { useContext } from "react";
import { IoClose } from "react-icons/io5";

const calculateBeforeTaxPrice = (data) => {
  let finalPrice = 0;
  data.forEach((item) => {
    finalPrice += item.count * item.price;
  });
  return finalPrice.toFixed(2);
};

const DemandOfPayment = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { searchPatients, patientFilter, userSession } =
    useContext(DrjubotContext);

  const patientFirstName = searchParams.get("patientFirstName");
  const patientLastName = searchParams.get("patientLastName");

  const [serviceList, setServiceList] = useState(false);
  const [serviceDictionaryList, setServiceDictionaryList] = useState([]);

  const [patientsList, setPatientsList] = useState(false);
  const [addPatientPopup, setAddPatientPopup] = useState(false);

  const [inputPlaceholder, setInputPlaceholder] = useState(
    patientFirstName + " " + patientLastName
  );

  const [inputPlaceholderNew, setInputPlaceholderNew] = useState("");

  const [data, setData] = useState([{ count: 1, service: "", price: 0 }]);

  const [addPatientFormData, setAddPatientFormData] = useState({
    patientid: "",
    company: false,
    firstname: "",
    lastname: "",
    address: "",
    mobile: "",
    email: "",
    session: userSession,
  });

  const [validation, setValidation] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [paymentValidation, setPaymentValidation] = useState("");

  const [formData, setFormData] = useState({
    patientid: "",
    proforma: true,
    proformaid: 0,
    session: userSession,
    amount: 1,
    vat: 17,
    total: 1.0,
    assignmentnumber: "",
    service: [{ description: "", amout: 0, quantity: 0 }],
    paymentdata: [{ paymenttype: 0, amount: 0, date: Date(), data: "" }],
  });

  const getServiceList = () => {
    console.log("HI");
    fetch("https://bo.drjubot.com/invoices.asmx/invoicegetservicedictionary", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setServiceDictionaryList(data);
      });
  };

  const addPatient = (e) => {
    e.preventDefault();
    fetch("https://bo.drjubot.com/patients.asmx/patientadd", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(addPatientFormData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (
          addPatientFormData.patientid === "" ||
          addPatientFormData.firstname === "" ||
          addPatientFormData.lastname === "" ||
          addPatientFormData.mobile === ""
        ) {
          setValidation("אנא מלאו את השדות בטופס");
        } else {
          setSuccessMessage("הלקוח/ה נשמר בהצלחה");
          setInputPlaceholderNew(
            addPatientFormData.firstname + " " + addPatientFormData.lastname
          );
          setAddPatientPopup(false);
          console.log(data);
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };

  useEffect(() => {
    if (inputPlaceholderNew !== "") {
      setInputPlaceholder("");
    }
  }, [inputPlaceholderNew]);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch("https://bo.drjubot.com/invoices.asmx/invoiceadd", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return res.json();
    });
  };

  return (
    <div className="demand-container">
      {addPatientPopup && (
        <div className="add-patient-popup">
          <div className="close-icon-title-container">
            <h3 className="add-patient-title">לקוח חדש</h3>
            <IoClose
              size={24}
              className="close-icon"
              onClick={() => {
                setAddPatientPopup(!addPatientPopup);
              }}
            />
          </div>
          <form
            className="add-patient-form-container"
            onSubmit={(e) => {
              addPatient(e);
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                gap: "32px",
              }}
            >
              <div style={{ width: "100%" }}>
                <h4 className="add-patient-input-label">ת.ז</h4>
                <input
                  className="add-patient-input"
                  type="text"
                  onChange={(e) =>
                    setAddPatientFormData({
                      ...addPatientFormData,
                      patientid: e.target.value,
                    })
                  }
                />
              </div>
              <div style={{ width: "100%" }}>
                <h4 className="add-patient-input-label">חברה</h4>
                <input
                  className="add-patient-input-check"
                  type="checkbox"
                  onChange={(e) =>
                    setAddPatientFormData({
                      ...addPatientFormData,
                      company: e.target.checked,
                    })
                  }
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                gap: "32px",
              }}
            >
              <div style={{ width: "100%" }}>
                <h4 className="add-patient-input-label">שם פרטי</h4>
                <input
                  className="add-patient-input"
                  type="text"
                  onChange={(e) =>
                    setAddPatientFormData({
                      ...addPatientFormData,
                      firstname: e.target.value,
                    })
                  }
                />
              </div>
              <div style={{ width: "100%" }}>
                <h4 className="add-patient-input-label">שם משפחה</h4>
                <input
                  className="add-patient-input"
                  type="text"
                  onChange={(e) =>
                    setAddPatientFormData({
                      ...addPatientFormData,
                      lastname: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div style={{ display: "flex", width: "100%", gap: "32px" }}>
              <div style={{ width: "100%" }}>
                <h4 className="add-patient-input-label">טלפון</h4>
                <input
                  className="add-patient-input"
                  type="text"
                  onChange={(e) =>
                    setAddPatientFormData({
                      ...addPatientFormData,
                      mobile: e.target.value,
                    })
                  }
                />
              </div>
              <div style={{ width: "100%" }}>
                <h4 className="add-patient-input-label">אי מייל</h4>
                <input
                  className="add-patient-input"
                  type="text"
                  onChange={(e) =>
                    setAddPatientFormData({
                      ...addPatientFormData,
                      email: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div style={{ display: "flex", width: "100%" }}>
              <div style={{ width: "100%" }}>
                <h4 className="add-patient-input-label">כתובת</h4>
                <input
                  className="add-patient-input-address"
                  type="text"
                  onChange={(e) =>
                    setAddPatientFormData({
                      ...addPatientFormData,
                      address: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            {validation && <p style={{ color: "red" }}>{validation}</p>}
            <button type="submit" className="add-patient-button">
              הוספת לקוח
            </button>
            {successMessage && (
              <p
                style={{
                  color: "#2d374",
                  alignSelf: "flex-end",
                  display: "flex",
                }}
              >
                {successMessage}
              </p>
            )}
          </form>
        </div>
      )}
      <h1 className="demand-title">דרישת תשלום</h1>
      <form className="demand-form-container" onSubmit={(e) => handleSubmit(e)}>
        <h4 className="demand-input-label">שם המטופל</h4>
        <input
          className="demand-input"
          type="text"
          value={
            inputPlaceholder === "" ? inputPlaceholderNew : inputPlaceholder
          }
          onFocus={() => {
            setInputPlaceholder("");
          }}
          onChange={(e) => {
            if (e.target.value === "") {
              setPatientsList(false);
            } else {
              setPatientsList(true);
              searchPatients(e.target.value);
              setInputPlaceholder("");
              setInputPlaceholder(e.target.value);
            }
          }}
        />
        {patientFilter.length > 0 && patientsList && (
          <div className="patients-list-container">
            {patientFilter.map((patient, i) => {
              return (
                <button
                  type="button"
                  key={i}
                  className="list-item-container"
                  onClick={() => {
                    setPatientsList(false);
                    setInputPlaceholderNew(
                      patient.firstname + " " + patient.lastname
                    );
                    setFormData({ ...formData, patientid: patient.patientid });
                  }}
                >
                  <h4 className="list-item">
                    {patient.firstname} {patient.lastname}
                  </h4>
                  <h4 className="list-item">ת.ז: {patient.patientid}</h4>
                  <h4 className="list-item">{patient.mobile}</h4>
                </button>
              );
            })}
            <button
              type="button"
              className="add-patient-button-list"
              onClick={() => {
                setInputPlaceholder("");
                setPatientsList(false);
                setAddPatientPopup(!addPatientPopup);
              }}
            >
              הוסף לקוח חדש +
            </button>
          </div>
        )}

        <div className="payment-container">
          {data.map((data, i) => {
            return (
              <div key={i + " "} className="payment-form-container">
                <div className="input-label-container">
                  <label className="label-payment">כמות</label>
                  <input
                    className="input-payment-amount"
                    type="number"
                    value={data.count}
                    onChange={(e) => {
                      setData((prev) =>
                        prev.map((d, index) =>
                          i === index ? { ...d, count: e.target.value } : d
                        )
                      );
                    }}
                  />
                </div>

                <div className="input-label-container">
                  <label className="label-payment">תיאור</label>
                  <input
                    className="input-payment-service"
                    type="text"
                    value={data.service}
                    onChange={(e) => {
                      if (e.target.value.length >= 1) {
                        setServiceList(true);
                        getServiceList();
                      } else {
                        setServiceList(false);
                      }
                      setData((prev) =>
                        prev.map((d, index) =>
                          i === index ? { ...d, service: e.target.value } : d
                        )
                      );
                    }}
                  />
                  {serviceList && (
                    <div className="service-list-container">
                      {serviceDictionaryList.map((service, i) => {
                        return (
                          <button
                            type="button"
                            key={i}
                            className="list-item-container"
                          >
                            <h4 className="list-item">{service}</h4>
                          </button>
                        );
                      })}
                    </div>
                  )}
                </div>

                <div className="input-label-container">
                  <label className="label-payment">מחיר</label>
                  <input
                    className="input-payment-price"
                    type="number"
                    onChange={(e) => {
                      setData((prev) =>
                        prev.map((d, index) =>
                          i === index ? { ...d, price: e.target.value } : d
                        )
                      );
                    }}
                  />
                </div>
              </div>
            );
          })}
          <button
            className="add-another-service-button"
            type="button"
            onClick={() => {
              if (data.find((d) => d.service === "" || d.price === 0)) {
                setPaymentValidation("אנא מלאו את כל השדות");
              } else {
                setPaymentValidation("");
                setData([...data, { count: 1, service: "", price: 0 }]);
              }
            }}
          >
            הוספת שורה +
          </button>
          {paymentValidation && (
            <p style={{ color: "red", display: "flex" }}>{paymentValidation}</p>
          )}
        </div>

        <div className="calculate-container">
          <h4 className="calculate-text">
            סה״כ: {calculateBeforeTaxPrice(data)} ₪
          </h4>
          <h4 className="calculate-text">
            מע״מ: {(calculateBeforeTaxPrice(data) * 0.17).toFixed(2)} ₪
          </h4>
          <hr className="horizontal-line"></hr>
          <h3 className="calculate-text-all">
            סה״כ: {(calculateBeforeTaxPrice(data) * 1.17).toFixed(2)} ₪
          </h3>
        </div>
        <div className="buttons-container">
          <button type="button" className="preview-button">
            תצוגה מקדימה
          </button>
          <button type="submit" className="continue-button">
            המשך
          </button>
        </div>
      </form>
    </div>
  );
};

export default DemandOfPayment;
