import React from "react";
import "./HeaderDashboard.css";
import { IoSearchOutline } from "react-icons/io5";
import { useContext } from "react";
import { DrjubotContext } from "../DrjubotContextProvider";
import { useLocation } from "react-router-dom";

const HeaderDashboard = ({ title, placeholder }) => {
  const { searchPatients } = useContext(DrjubotContext);
  const location = useLocation();

  return (
    <div className="header-container">
      <div className="title-container">
        <h1 className="header-title">{title}</h1>
      </div>
      <div className="search-button-container">
        <div className="search-container">
          {location.pathname === "/dashboard/main" ? (
            <></>
          ) : (
            <>
              <input
                className="header-input"
                type="text"
                placeholder={placeholder ?? ""}
                onChange={(e) => {
                  if (e.target.value === "") {
                    searchPatients("271");
                  } else searchPatients(e.target.value);
                }}
              />
              <IoSearchOutline className="search-svg" size={24} />
            </>
          )}
        </div>
        <button type="button" className="header-add-invoice-button">
          צור חשבונית
          <img src="../assets/round btn.svg" />
        </button>
      </div>
    </div>
  );
};

export default HeaderDashboard;
