import { createContext, useState, useEffect } from "react";

export const DrjubotContext = createContext({
  userSession: "",
  patientFilter: [],
  setPatientFilter: () => {},
  searchPatients: () => {},
});

export const getPatients = (searchInput, userSession) => {
  return fetch("https://bo.drjubot.com/patients.asmx/patientsearch", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      patientid: searchInput,
      name: searchInput,
      mobile: searchInput,
      session: userSession,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      if (data.length === 0) {
        return [];
      } else {
        return data;
      }
    })
    .catch((error) => {
      console.error("Fetch error:", error);
    });
};

export default function DrjubotContextProvider({ children }) {
  const [patientFilter, setPatientFilter] = useState([{}]);
  const userSession = localStorage.getItem("session");
  const number = Math.floor(Math.random() * (999 - 100 + 1) + 100);
  const [currentUser, setCurrentUser] = useState({
    fullname: "",
    email: "",
    image: "",
    session: "",
  });

  useEffect(() => {
    getPatients(number, userSession).then((data) => setPatientFilter(data));
  }, []);

  useEffect(() => {
    const userName = localStorage.getItem("username");
    if (userName) {
      fetch("https://bo.drjubot.com/users.asmx/userexists", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: userName,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          if (data.length === 0) {
            return [];
          } else {
            console.log(data.userimage);
            setCurrentUser({
              fullname: data.fullname,
              email: data.email,
              image: data.userimage,
              session: data.session,
            });
            return data;
          }
        })
        .catch((error) => {
          console.error("Fetch error:", error);
        });
    }
  }, []);

  const searchPatients = (inputSearch) => {
    getPatients(inputSearch, userSession).then((data) =>
      setPatientFilter(data)
    );
  };

  return (
    <DrjubotContext.Provider
      value={{
        userSession,
        patientFilter,
        setPatientFilter,
        searchPatients,
        currentUser,
        setCurrentUser,
      }}
    >
      {children}
    </DrjubotContext.Provider>
  );
}
