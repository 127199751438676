import React, { useContext } from "react";
import "./UserSideNav.css";
import { Link } from "react-router-dom";
import { DrjubotContext } from "../DrjubotContextProvider";

const UserSideNav = () => {
  const { currentUser } = useContext(DrjubotContext);
  console.log(currentUser.image);
  return (
    <div className="user-sidenav-container">
      <div className="user-details">
        <img
          src={`data:image/png;base64, ${currentUser.image}`}
          className="user-photo"
        />
        <div>
          <h1 className="name">{currentUser.fullname}</h1>
          <h2 className="email">{currentUser.email}</h2>
        </div>
      </div>
      <div className="all-right-container">
        <h1 className="all-right-text">All right reserved to DrJubot©</h1>
        <Link>Terms and condition</Link>
      </div>
    </div>
  );
};

export default UserSideNav;
