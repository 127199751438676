import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Auth/Login";
import Home from "./Pages/Home";
import Register from "./Auth/Register";
import ForgotPass from "./Auth/ForgotPass";
import Dashboard from "./Pages/Dashboard";
import Profile from "./Pages/Profile";
import DemandOfPayment from "./Pages/DemandOfPayment";
import React from "react";
import MainDashboard from "./Pages/MainDashboard";
import AfterForgotPass from "./Auth/AfterForgotPass";
import Patients from "./Pages/Patients";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/forgot-password" element={<ForgotPass />}></Route>
        <Route
          path="/after-forgot-password"
          element={<AfterForgotPass />}
        ></Route>
        <Route path="/dashboard" element={<Dashboard />}>
          <Route path="main" element={<MainDashboard />}></Route>
          <Route path="patients" element={<Patients />}></Route>
          <Route path="profile" element={<Profile />}></Route>
          <Route path="demand-of-payment" element={<DemandOfPayment />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
