import React from "react";
import SideNav from "../Components/SideNav";
import { Outlet } from "react-router-dom";

const Dashboard = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#FAFAFA",
        width: "100%",
      }}
    >
      <SideNav />
      <div
        style={{
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
