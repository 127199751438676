import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const RegisterPart3 = ({ formData, setFormData }) => {
  const [validation, setValidation] = useState("");
  const navigate = useNavigate();
  const [bankList, setBankList] = useState([
    {
      name: "",
      code: 0,
    },
  ]);
  const [bankValid, setBankValid] = useState({
    bankcode: 0,
    branch: "",
    accountnumber: "",
  });

  useEffect(() => {
    getBankList();
  }, []);

  const isBankValid = () => {
    fetch("https://bo.drjubot.com/general.asmx/bankvalid", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bankValid),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(bankValid);
        console.log(data);
        if (data.code !== 0) {
          setValidation(data.message);
          return;
        } else {
          console.log("all good");
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };

  const getBankList = () => {
    fetch("https://bo.drjubot.com/general.asmx/banklist", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setBankList(data);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };

  const handleSubmit = (e) => {
    isBankValid();
    e.preventDefault();
    fetch("https://bo.drjubot.com/organisations.asmx/organisationadd", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (
          formData.bankdetails.bankname === "" ||
          formData.bankdetails.branch === "" ||
          formData.bankdetails.accountname === "" ||
          formData.bankdetails.accountnumber === ""
        ) {
          setValidation("אחד מהשדות ריקים, אנא מלאו את כל השדות בטופס זה");
        } else if (data.error.code !== 0) {
          setValidation("ולידציה כלשהי");
        } else {
          setValidation("");
          navigate("/login");
        }
        console.log(data);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };
  return (
    <div className="box-container">
      <h1 className="title">פרטי בנק</h1>
      <form onSubmit={(e) => handleSubmit(e)} className="form">
        <h2 className="label">בנק</h2>
        <select
          className="input"
          onChange={(e) => {
            let selectedOption = e.target.selectedOptions[0];
            const updateBankdetails = {
              ...formData.bankdetails,
              bankname: selectedOption.text,
              bankcode: selectedOption.value,
            };
            setFormData({
              ...formData,
              bankdetails: updateBankdetails,
            });
            setBankValid({
              ...bankValid,
              bankcode: selectedOption.value,
            });
          }}
        >
          {(bankList ?? []).map((bank, i) => {
            return (
              <option key={i + ""} value={bank.code}>
                {bank.name}
              </option>
            );
          })}
        </select>

        <h2 className="label">סניף</h2>
        <input
          className="input"
          type="text"
          onChange={(e) => {
            const updateBankdetails = {
              ...formData.bankdetails,
              branch: e.target.value,
            };
            setFormData({
              ...formData,
              bankdetails: updateBankdetails,
            });
            setBankValid({
              ...bankValid,
              branch: e.target.value,
            });
          }}
        />

        <h2 className="label">חשבון על שם</h2>
        <input
          className="input"
          type="text"
          onChange={(e) => {
            const updateBankdetails = {
              ...formData.bankdetails,
              accountname: e.target.value,
            };
            setFormData({
              ...formData,
              bankdetails: updateBankdetails,
            });
          }}
        />

        <h2 className="label">מספר חשבון</h2>
        <input
          className="input"
          type="text"
          onChange={(e) => {
            const updateBankdetails = {
              ...formData.bankdetails,
              accountnumber: e.target.value,
            };
            setFormData({
              ...formData,
              bankdetails: updateBankdetails,
            });
            setBankValid({ ...bankValid, accountnumber: e.target.value });
          }}
        />
        <div>
          <input
            checked={formData.showbankdetails}
            type="checkbox"
            onChange={() =>
              setFormData({
                ...formData,
                showbankdetails: !formData.showbankdetails,
              })
            }
          />
          <label className="label-check">הצג פרטי בנק על דרישת תשלום</label>
        </div>
        {validation && <p style={{ color: "red" }}>{validation}</p>}
        <button type="submit" className="submit-button">
          המשך
        </button>
      </form>
    </div>
  );
};

export default RegisterPart3;
