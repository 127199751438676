import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./ForgotPass.css";
import Footer from "../Components/Footer";

const ForgotPass = () => {
  const [validation, setValidation] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [userName, setUserName] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    htmlmailmessage: `<html><body>${userName} זאת הסיסמא החדשה שלך $PASSWORD</body></html>`,
    wamessage: true,
  });
  const navigate = useNavigate();

  useEffect(() => {
    handleUserExists();
  }, [formData.name]);

  const handleUserExists = () => {
    fetch("https://bo.drjubot.com/users.asmx/userexists", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: formData.name,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (formData.name === "") {
          setValidation("");
        } else if (data.name === formData.name) {
          setValidation("");
          return true;
        } else {
          setValidation(
            "שם המשתמש אינו קיים במערכת. אנא הירשמו בתור משתמש חדש"
          );
          return false;
        }
        console.log(data);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    fetch("https://bo.drjubot.com/users.asmx/userresetpassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setShowSuccess(true);
        console.log(data);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };

  return (
    <div className="page-container">
      <img src="../assets/logo.svg" alt="DrJubot Logo" className="logo" />
      <div className="box-container">
        <h1 className="title">שכחתי סיסמה</h1>
        {showSuccess ? (
          <>
            <h2 className="title-sec">
              אם שם המשתמש קיים, נשלחה למייל הרשום הודעת אתחול לסיסמת המשתמש
            </h2>
            <button
              type="button"
              onClick={() => navigate("/login")}
              className="submit-button"
            >
              כניסה למערכת
            </button>
          </>
        ) : (
          <form onSubmit={(e) => handleSubmit(e)} className="form">
            <h2 className="label">שם משתמש</h2>
            <input
              type="text"
              autofill="off"
              dir="ltr"
              onChange={(e) => {
                setUserName(e.target.value);
                setFormData({
                  name: e.target.value,
                  htmlmailmessage: `<html><body>${userName} this is your temporary new password $PASSWORD</body></html>`,
                  wamessage: true,
                });
              }}
              className="input"
            />
            {validation && <p style={{ color: "red" }}>{validation}</p>}
            <button
              className="submit-button"
              disabled={validation}
              type="submit"
              style={{ cursor: "pointer" }}
            >
              המשך
            </button>
            <div className="not-register-yet-container">
              <h2 className="not-register-yet-title">רישום משתמש חדש</h2>
              <button
                type="button"
                onClick={() => {
                  navigate("/register");
                }}
                className="register-button"
              >
                אישור
              </button>
            </div>
          </form>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ForgotPass;
