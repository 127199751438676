import React, { useContext, useState } from "react";
import "./Profile.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DrjubotContext } from "../DrjubotContextProvider";

const Profile = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const patientId = searchParams.get("patientId");
  const patientFirstName = searchParams.get("patientFirstName");
  const patientLastName = searchParams.get("patientLastName");
  const patientPhone = searchParams.get("patientPhone");
  const patientEmail = searchParams.get("patientEmail");
  const patientAddress = searchParams.get("patientAddress");
  const company = searchParams.get("company");
  const navigate = useNavigate();
  const { userSession } = useContext(DrjubotContext);
  const [profileFormData, setProfileFormData] = useState({
    patientid: patientId,
    company: company,
    firstname: patientFirstName,
    lastname: patientLastName,
    address: patientAddress,
    mobile: patientPhone,
    email: patientEmail,
    session: userSession,
  });

  const updatePatient = (e) => {
    e.preventDefault();
    fetch("https://bo.drjubot.com/patients.asmx/patientadd", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(profileFormData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingTop: "48px",
        paddingBottom: "48px",
      }}
    >
      <div className="profile-container">
        <button
          type="button"
          className="back-to-patintes-button"
          onClick={() => {
            navigate("/dashboard/patients");
          }}
        >
          בחזרה למטופלים
        </button>
        <h1 className="profile-title">עריכת פרטי מטופל</h1>
        <form className="profile-form" onSubmit={(e) => updatePatient(e)}>
          <h4 className="profile-input-label">ת.ז</h4>
          <input
            className="profile-input"
            disabled
            type="text"
            placeholder={patientId}
          />
          <h4 className="profile-input-label">שם פרטי</h4>
          <input
            className="profile-input"
            type="text"
            placeholder={patientFirstName}
            onChange={(e) =>
              setProfileFormData({
                ...profileFormData,
                firstname: e.target.value,
              })
            }
          />{" "}
          <h4 className="profile-input-label">שם משפחה</h4>
          <input
            className="profile-input"
            type="text"
            placeholder={patientLastName}
            onChange={(e) =>
              setProfileFormData({
                ...profileFormData,
                lastname: e.target.value,
              })
            }
          />
          <h4 className="profile-input-label">כתובת</h4>
          <input
            className="profile-input"
            type="text"
            placeholder={patientAddress}
            onChange={(e) =>
              setProfileFormData({
                ...profileFormData,
                address: e.target.value,
              })
            }
          />
          <h4 className="profile-input-label">טלפון</h4>
          <input
            className="profile-input"
            type="text"
            placeholder={patientPhone}
            onChange={(e) =>
              setProfileFormData({
                ...profileFormData,
                mobile: e.target.value,
              })
            }
          />
          <h4 className="profile-input-label">אימייל</h4>
          <input
            className="profile-input"
            type="text"
            placeholder={patientEmail}
            onChange={(e) =>
              setProfileFormData({
                ...profileFormData,
                email: e.target.value,
              })
            }
          />
          <button className="profile-save-button" type="submit">
            שמירה
          </button>
        </form>
      </div>
    </div>
  );
};

export default Profile;
